import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"
import { Formik } from "formik"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer/index"

import { FiArrowUpRight } from "react-icons/fi"
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa"
import { MdMail } from "react-icons/md"

// import api from "../services/api"

import colors from "../styles/colors"
import { Container } from "../styles/global"

import ContatoImg from "../images/Contato/contato.svg"

function ContatoPage() {
  // const [emailSentSuccess, setEmailSentSuccess] = useState(false)

  async function handleSubmitDireta(data) {
    // //console.log(data)
    // const response = await api.post("/api/diretaSite", data)
    // //console.log(response)
    // if (response.status === 200) {
    //   setEmailSentSuccess(true)
    //   setTimeout(() => {
    //     console.log("redirecting now")
    //   }, 100)
    // }
  }

  return (
    <>
      <SEO title="Contato" />
      <Header />
      <Container>
        <Top>
          <h1>Fale conosco</h1>
          <Cards>
            <TopCard>
              <div>
                <FaPhoneAlt size={20} />
                <p>Ligue para a Ardrak</p>
              </div>
              <a href="tel:6235469393" target="_blank" rel="noreferrer">
                62 3546-9393 <FiArrowUpRight size={20} />
              </a>
            </TopCard>

            <TopCard>
              <div>
                <FaWhatsapp size={20} />
                <p>Mensagem via Whatsapp</p>
              </div>
              <a
                href="https://wa.me/5562992966223"
                target="_blank"
                rel="noreferrer"
              >
                62 99296-6223 <FiArrowUpRight size={20} />
              </a>
            </TopCard>
            <TopCard>
              <div>
                <MdMail size={20} />
                <p>Envie um e-mail</p>
              </div>
              <a
                href="mail:contato@ardrak.com.br"
                target="_blank"
                rel="noreferrer"
              >
                vendas@ardrak.com.br <FiArrowUpRight size={20} />
              </a>
            </TopCard>
          </Cards>
        </Top>
        <Content>
          <Left>
            <img src={ContatoImg} alt="Contato" />
          </Left>
          <Right>
            <h2>Envie uma mensagem</h2>

            <Formik
              initialValues={{
                quero_ser_parceiro_ardrak: "",
                nome: "",
                estado: "",
                cidade: "",
                email: "",
                telefone: "",
                mensagem: "",
              }}
              onSubmit={handleSubmitDireta}
            >
              {({ handleSubmit, values, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <div className="checkbox">
                    <input
                      id="quero_ser_parceiro_ardrak"
                      type="checkbox"
                      name="quero_ser_parceiro_ardrak"
                      value={values.quero_ser_parceiro_ardrak}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="quero_ser_parceiro_ardrak">
                      Quero ser parceiro Ardrak
                    </label>
                  </div>
                  <input
                    placeholder="Seu nome"
                    id="nome"
                    type="text"
                    name="nome"
                    value={values.nome}
                    onChange={handleChange}
                    required
                  />

                  <div className="endereco">
                    <input
                      className="estado"
                      placeholder="Estado"
                      id="estado"
                      type="text"
                      name="estado"
                      value={values.estado}
                      onChange={handleChange}
                      required
                    />

                    <input
                      className="cidade"
                      placeholder="Cidade"
                      id="cidade"
                      type="text"
                      name="cidade"
                      value={values.cidade}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <input
                    placeholder="E-mail"
                    id="email"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />

                  <input
                    placeholder="Telefone ou celular"
                    id="telefone"
                    type="text"
                    name="telefone"
                    value={values.telefone}
                    onChange={handleChange}
                    required
                  />

                  <textarea
                    placeholder="Mensagem"
                    id="mensagem"
                    type="text"
                    name="mensagem"
                    onChange={handleChange}
                    value={values.mensagem}
                  />

                  <button type="submit">Enviar mensagem</button>
                </form>
              )}
            </Formik>
          </Right>
        </Content>
      </Container>
      <Footer />
    </>
  )
}

const Top = styled.div`
  margin-top: 50px;

  display: flex;
  flex-direction: column;

  font-size: 20px;
  line-height: 22px;
  color: ${colors.CINZA_ESCURO};

  h1 {
    font-size: 40px;
    line-height: 44px;
    color: ${colors.VERDE_ESCURO};
    margin-bottom: 5px;
  }

  @media (max-width: 1199px) {
    h1 {
      text-align: center;
    }
  }
`

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1199px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 499px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const TopCard = styled.div`
  border: 1px solid ${colors.CINZA_PADRAO};

  padding: 25px 90px 25px 40px;
  border-radius: 10px;

  width: 100%;
  max-width: 381px;

  div {
    display: flex;
    align-items: center;

    margin-bottom: 3px;

    svg {
      margin-right: 10px;
      color: ${colors.CINZA_CONTATO_CARD};
    }

    p {
      margin: 3px 0px 0px 0px;
      padding: 0px;
      color: ${colors.CINZA_CONTATO_CARD};
    }
  }

  a {
    text-decoration: none;
    color: ${colors.VERDE_CONTATO_CARD};
    align-items: flex-start;

    svg {
      padding-top: 3px;
    }

    &:hover {
      color: ${colors.VERDE};
    }
  }

  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }

  @media (max-width: 499px) {
    padding-right: 0px;
  }

  @media (max-width: 320px) {
    padding: 20px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 100px 0px;

  @media (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 975px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 60px 0px;
  }
`

const Left = styled.div`
  width: 100%;
  max-width: 446px;

  img {
    width: 100%;
    max-width: 470px;
  }

  @media (max-width: 975px) {
    img {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 499px) {
    img {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  max-width: 446px;

  div.checkbox {
    display: flex;
    align-items: center;

    height: 30px;

    label {
      font-size: 18px;
      color: #434343;
      margin-bottom: 4px;
      margin-left: 2px;
    }
  }

  h2 {
    text-align: left;
    align-self: flex-start;
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    color: ${colors.VERDE_ESCURO};
    margin-bottom: 5px;
  }

  form {
    width: 100%;
    max-width: 446px;
    display: flex;
    flex-direction: column;

    div.endereco {
      display: flex;
      justify-content: space-between;

      input.estado {
        width: 100%;
        max-width: 145px;

        @media (max-width: 499px) {
          margin-right: 10px;
        }
      }
      input.cidade {
        width: 100%;
        max-width: 288px;
      }
    }

    input {
      font-size: 20px;
      font-weight: 500;
      font-family: "Founders Grotesk", sans-serif;
      color: ${colors.CINZA_ESCURO};

      border: 2px solid rgba(54, 54, 54, 0.5);
      border-radius: 8px;

      padding: 10px 7px 4px 12px;
      margin-bottom: 15px;

      height: 60px;
    }

    textarea {
      font-size: 20px;
      font-family: "Founders Grotesk", sans-serif;
      font-weight: 500;
      color: ${colors.CINZA_ESCURO};

      border: 2px solid rgba(54, 54, 54, 0.5);
      border-radius: 8px;
      padding: 10px 7px 5px 12px;

      height: 240px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      max-width: 246px;
      background-color: ${colors.VERDE};
      color: white;

      border: none;
      border-radius: 25px;

      padding: 16px 10px 10px 10px;
      margin-top: 10px;

      align-self: flex-end;
      width: 100%;
      max-width: 219px;

      &:hover {
        background-color: ${colors.VERDE_ESCURO};
      }
    }
  }

  @media (max-width: 499px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
`

export default ContatoPage
